<template>
  <div class="mt-4 mb-8 container">
    <h2>Instructions:</h2>
    <ol>
      <li>Upload a PDF form with fillable fields.</li>
      <li>Review the list of fields displayed from the PDF form.</li>
      <li>
        Upload a CSV file with data to fill the PDF form. Ensure the CSV file
        has column headers matching the PDF form fields.
      </li>
      <li>Preview the CSV data in the datatable.</li>
      <li>Download individual filled PDFs or all filled PDFs at once.</li>
    </ol>

    <v-file-input label="Select PDF" @change="loadPDF" />

    <div v-if="pdfDoc">
      <h3>Form Fields:</h3>
      <v-chip-group column>
        <v-chip v-for="field in formFields" :key="field.name">
          {{ field.name }} ({{ field.type }})
        </v-chip>
      </v-chip-group>
    </div>
    <v-divider class="my-4" />
    <v-file-input v-if="pdfDoc" label="Select CSV" @change="loadCSV" />

    <v-select
      v-if="csvRows.length"
      v-model="font"
      :items="fontOptions"
      label="Font"
    ></v-select>

    <v-select
      v-if="csvRows.length"
      v-model="fontSize"
      :items="fontSizes"
      label="Font Size"
      clearable
    ></v-select>

    <v-data-table
      v-if="csvRows.length"
      :headers="csvHeaders"
      :items="csvRows"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:[`item.action`]="{ item, index }">
        <v-btn
          color="primary"
          @click="fillAndDownloadPDF(item, index)"
          :loading="item.isDownloading"
        >
          Fill & Download PDF
        </v-btn>
      </template>
    </v-data-table>

    <v-btn
      v-if="csvRows.length"
      color="primary"
      class="my-3 mx-auto"
      @click="fillAndDownloadAllPDFs"
      :loading="isMergingAndDownloading"
    >
      Fill & Download All PDFs
    </v-btn>

    <v-dialog v-model="warningDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline">Skipped Fields</v-card-title>
        <v-card-text>
          <div v-for="message in skippedFields" :key="message">
            <v-alert type="error">{{ message }}</v-alert>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="warningDialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  loadPDFDocument,
  getFormFields,
  fillForm,
} from "@/services/pdfFiller.js";
import { parseCSV } from "@/services/csvParser.js";
import { PDFDocument } from "pdf-lib";

export default {
  data() {
    return {
      pdfDoc: null,
      formFields: [],
      csvRows: [],
      isMergingAndDownloading: false,
      skippedFields: [],
      warningDialog: false,
      fontSize: null,
      fontSizes: [
        8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 20, 24, 28, 32, 36, 40, 48, 56, 64, 72,
      ],
      font: 'Tahoma', // default font
      fontOptions: [ 'KanchaTH', 'NotoSansThai', 'Tahoma', 'TahomaBD' ],
    };
  },
  computed: {
    csvHeaders() {
      if (this.csvRows.length === 0) return [];
      const headers = Object.keys(this.csvRows[0]).map((key) => {
        return { text: key, value: key };
      });
      headers.unshift({ text: "Action", value: "action" });
      return headers;
    },
  },
  methods: {
    async loadPDF(file) {
      try {
        this.pdfDoc = await loadPDFDocument(file);
        this.formFields = getFormFields(this.pdfDoc);
      } catch (error) {
        console.error("Error loading PDF:", error);
      }
    },
    async fillAndDownloadPDF(item, index) {
      try {
        item.isDownloading = true;

        // Create a new instance of the PDF document
        const pdfBytes = await this.pdfDoc.save();
        const newPdfDoc = await PDFDocument.load(pdfBytes);

        // Fill the new PDF document with data from the current row
        const { pdfBytes: filledPdfBytes, skippedFields } = await fillForm(
          newPdfDoc,
          item,
          { fontSize: this.fontSize, font: this.font }
        );
        this.skippedFields = skippedFields;
        // If there are skipped fields, show the dialog
        if (skippedFields.length > 0) {
          this.warningDialog = true;
        }

        // Download the filled PDF
        const blob = new Blob([filledPdfBytes], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `filled_form_${index + 1}.pdf`;
        link.click();
        item.isDownloading = false;
      } catch (error) {
        console.error("Error filling PDF:", error);
        item.isDownloading = false;
      }
    },
    async loadCSV(file) {
      if (!this.pdfDoc) return;

      try {
        const csvData = await file.text();
        this.csvRows = parseCSV(csvData);
      } catch (error) {
        console.error("Error loading CSV:", error);
      }
    },
    async fillAndDownloadAllPDFs() {
      this.isMergingAndDownloading = true;
      try {
        const filledPDFs = await Promise.all(
          this.csvRows.map(async (row) => {
            const pdfBytes = await this.pdfDoc.save();
            const newPdfDoc = await PDFDocument.load(pdfBytes);

            const { pdfBytes: filledPdfBytes, skippedFields } = await fillForm(
              newPdfDoc,
              row,
              { fontSize: this.fontSize, font: this.font }
            );
            this.skippedFields = skippedFields;
            // If there are skipped fields, show the dialog
            if (skippedFields.length > 0) {
              this.warningDialog = true;
            }

            return filledPdfBytes;
          })
        );

        // Create a new PDFDocument
        const mergedPdfDoc = await PDFDocument.create();

        // Iterate over the filled PDFs and add their pages to the merged document
        for (const pdfBytes of filledPDFs) {
          const pdf = await PDFDocument.load(pdfBytes);
          const pageCount = pdf.getPageCount();
          for (let i = 0; i < pageCount; i++) {
            const [page] = await mergedPdfDoc.copyPages(pdf, [i]);
            mergedPdfDoc.addPage(page);
          }
        }

        // Serialize the merged document to bytes
        const mergedPdfBytes = await mergedPdfDoc.save();

        // Download the merged PDF
        const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `filled_forms_merged.pdf`;
        link.click();
      } catch (error) {
        console.error("Error filling and merging PDFs:", error);
      }

      this.isMergingAndDownloading = false;
    },
  },
};
</script>
