// src/utils/pdfFiller.js

import { PDFDocument, PDFTextField, PDFCheckBox, PDFDropdown, PDFOptionList, PDFRadioGroup, PDFButton, PDFSignature } from "pdf-lib";
import fontkit from '@pdf-lib/fontkit';

const fontOptions = {
  'NotoSansThai': '/assets/fonts/NotoSansThai-VariableFont_wdth,wght.ttf',
  'KanchaTH': '/assets/fonts/KanchaTH.ttf',
  'Tahoma': '/assets/fonts/tahoma.ttf',
  'TahomaBD': '/assets/fonts/tahomabd.ttf',
}

export async function loadPDFDocument(file) {
  const buffer = await file.arrayBuffer();
  const pdfBytes = new Uint8Array(buffer);
  const pdfDoc = await PDFDocument.load(pdfBytes);
  return pdfDoc;
}

export function getFormFields(pdfDoc) {
  const form = pdfDoc.getForm();
  const fields = form.getFields();
  return fields.map(field => {
    const fieldType = getFieldType(field);
    return {
      name: field.getName(),
      type: fieldType
    };
  });
}

function getFieldType(field) {
  // Check if the argument is not an object
  if (typeof field !== 'object' || field === null) {
    return 'InvalidArgument';
  }

  if (typeof PDFTextField !== 'undefined' && field instanceof PDFTextField) {
    return 'PDFTextField';
  } else if (typeof PDFCheckBox !== 'undefined' && field instanceof PDFCheckBox) { // Updated the class name
    return 'PDFCheckBox'; // Updated the return value
  } else if (typeof PDFDropdown !== 'undefined' && field instanceof PDFDropdown) {
    return 'PDFDropdown';
  } else if (typeof PDFOptionList !== 'undefined' && field instanceof PDFOptionList) {
    return 'PDFOptionList';
  } else if (typeof PDFRadioGroup !== 'undefined' && field instanceof PDFRadioGroup) {
    return 'PDFRadioGroup';
  } else if (typeof PDFButton !== 'undefined' && field instanceof PDFButton) {
    return 'PDFButton';
  } else if (typeof PDFSignature !== 'undefined' && field instanceof PDFSignature) {
    return 'PDFSignature';
  } else {
    return 'UnsupportedFieldType';
  }
}

async function fetchFont(url) {
  const response = await fetch(url);
  const buffer = await response.arrayBuffer();
  return new Uint8Array(buffer);
}

export async function fillForm(pdfDoc, data, options = {}) {
  // Initialize the skippedFields array
  const skippedFields = [];

  // Register fontkit
  pdfDoc.registerFontkit(fontkit);

  // Register the font
  const optionFont = options.font ? fontOptions[options.font]:fontOptions['KanchaTH'];
  const fontBytes = await fetchFont(optionFont);
  const font = await pdfDoc.embedFont(fontBytes);

  const form = pdfDoc.getForm();
  const fields = form.getFields();
  for (const key in data) {
    const field = fields.find(f => f.getName().toLowerCase() === key.toLowerCase());
    if (field) {
      const fieldType = getFieldType(field);
      switch (fieldType) {
        case 'PDFButton':
          // TODO: implement setValue for PDFButton
          break;
        case 'PDFCheckBox':
          // TODO: implement setValue for PDFCheckBox
          break;
        case 'PDFDropdown':
          // TODO: implement setValue for PDFDropdown
          break;
        case 'PDFOptionList':
          // TODO: implement setValue for PDFOptionList
          break;
        case 'PDFRadioGroup':
          // TODO: implement setValue for PDFRadioGroup
          break;
        case 'PDFSignature':
          // TODO: implement setValue for PDFSignature
          break;
        case 'PDFTextField':
          field.setText(data[key]);
          if (options.fontSize) {
            field.setFontSize(options.fontSize);
          }
          // TODO: Add further customization options as needed
          break;
        default: {
          const unsupportedMessage = `Skipping field "${key}" - unsupported field type "${fieldType}"`;
          console.warn(unsupportedMessage);
          skippedFields.push(unsupportedMessage);
        }
      }
    } else {
      const notFoundMessage = `Skipping field "${key}" - not found in PDF form.`;
      console.warn(notFoundMessage);
      skippedFields.push(notFoundMessage);
    }
  }

  // Update the appearances for all fields in the form
  form.updateFieldAppearances(font);

  // Flatten the form (optional, but recommended)
  form.flatten();

  const pdfBytes = await pdfDoc.save();

  // Return both the pdfBytes and the skippedFields array
  return { pdfBytes, skippedFields };
}

