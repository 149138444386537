import Papa from "papaparse";

export function parseCSV(csvData) {
  const { data, errors } = Papa.parse(csvData, {
    encoding: 'utf-8', // Make sure to use the UTF-8 encoding
    header: true,
    skipEmptyLines: true,
  });

  if (errors.length) {
    throw new Error("Error parsing CSV");
  }

  return data;
}
